import React from 'react'

import Link, { Theme as LinkTheme } from 'components/base/Link'
import InsuranceTypeIcon, { Theme as InsuranceTypeIconTheme } from 'components/base/Icon/InsuranceTypeIcon'

import { Props } from './types'

import {
  Wrapper,
  Products,
  Product,
  FooterWrapper,
} from './styles'

const allProducts = {
  home: {
    to: '/products/homeowners-insurance',
    theme: InsuranceTypeIconTheme.home,
    title: 'Home',
  },
  auto: {
    to: '/products/auto-insurance',
    theme: InsuranceTypeIconTheme.auto,
    title: 'Auto',
  },
  life: {
    to: '/products/life-insurance',
    theme: InsuranceTypeIconTheme.life,
    title: 'Life',
  },
  health: {
    to: '/products/health-insurance',
    theme: InsuranceTypeIconTheme.health,
    title: 'Health',
  },
  medicare: {
    to: '/products/medicare-insurance',
    theme: InsuranceTypeIconTheme.medicare,
    title: 'Medicare',
  },
  disability: {
    to: '/products/disability-insurance',
    theme: InsuranceTypeIconTheme.disability,
    title: 'Disability',
  },
  business: {
    to: '/products/business-insurance',
    theme: InsuranceTypeIconTheme.business,
    title: 'Business',
  },
  retirement: {
    to: '/products/investments-retirement',
    theme: InsuranceTypeIconTheme.retirement,
    title: 'Retirement',
  },
}

const ProductGroup: React.FC<Props> = ({
  footer = null,
  header = null,
  products = [
    'home',
    'auto',
    'life',
    'health',
    'medicare',
    'disability',
    'business',
    'retirement',
  ],
}): React.ReactElement => (
  <Wrapper>
    {header}
    <Products>
      {products.map(product => allProducts[product] && (
        <Product key={allProducts[product].title}>
          <Link theme={LinkTheme.unstyled} to={allProducts[product].to}>
            <InsuranceTypeIcon theme={allProducts[product].theme} colored={true} />
            <span>{allProducts[product].title}</span>
          </Link>
        </Product>
      ))}
    </Products>
    {footer && <FooterWrapper>{footer}</FooterWrapper>}
  </Wrapper>
)

export default ProductGroup
